import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.all_loaded
    ? _c(
        VContainer,
        { attrs: { id: "category-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                icon: "mdi-currency-usd",
                inline: "",
                color: "secondary",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$t("vehicle_title")))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3995116199
              ),
            },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-test": "Vehicle:Vehicle:BtnAddNewVehicle",
                    color: "primary",
                    rounded: "",
                    absolute: "",
                    fab: "",
                    top: "",
                    right: "",
                  },
                  on: { click: _vm.addVehicle },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("company_id")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("nire")))]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("edit"))),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("remove"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.cleanVehicleData, function (vehicle, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        attrs: { "data-test": "Vehicle:Vehicle:Teste" },
                      },
                      [
                        _c("td", [_vm._v(_vm._s(vehicle.name))]),
                        _c("td", [
                          _vm._v(_vm._s(vehicle.cnpj ? vehicle.cnpj : "-")),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(vehicle.nire ? vehicle.nire : "-")),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "px-2 ml-1 secondary",
                                attrs: {
                                  "data-test":
                                    "Vehicle:Vehicle:BtnEdit:" + vehicle.name,
                                  "min-width": "0",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.ToEditVehicle(vehicle)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c(
                              VTooltip,
                              {
                                attrs: {
                                  bottom: "",
                                  color: "gray",
                                  disabled: vehicle.canBeDeleted,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "px-2 ml-1",
                                                  attrs: {
                                                    disabled:
                                                      !vehicle.canBeDeleted,
                                                    "data-test":
                                                      "Vehicle:Vehicle:BtnDelete:" +
                                                      vehicle.name,
                                                    color: "red",
                                                    "min-width": "0",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.open_delete_dialog(
                                                        vehicle
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-trash-can")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("can_be_deleted_vehicle"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.name },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.delete_vehicle,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }